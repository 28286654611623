<template>
  <div class="forget-model">
    <div class="model-title">
      {{$t('signIn.formTitle')}}
    </div>
    <div>
      <a-tabs>
        <a-tab-pane key="withPhone" :tab="$t('signIn.withPhone')">
          <a-form
            ref="formRef"
            name="custom-validation"
            :model="forgetForm"
            :rules="forgetRules"
            v-bind="layout"
            class="forget-form"
            @finish="signIn($event, true)"
            autocomplete="off"
          >
            <a-form-item
              :label="$t('signIn.phone')"
              name="phone">
              <a-input
                v-model:value="forgetForm.phone"
                autocomplete="off"
                :placeholder="$t('signIn.phoneNullError')">
                <template #prefix>
                  <div style="color: rgba(0,0,0,0.4)">
                    +86
                  </div>
                </template>
              </a-input>
            </a-form-item>
            <a-form-item
              :label="$t('signIn.verificationCode')"
              name="smsCode">
              <a-input
                v-model:value="forgetForm.smsCode"
                autocomplete="off"
                :placeholder="$t('signIn.verificationTips')">
                <template #suffix>
                  <div class="code-input-suffix">
                    <div class="divide-line"></div>
                    <span
                      @click="getCode"
                      v-if="canGetCode"
                      class="get-code-text">
                      {{$t('forgetPassword.getCode')}}
                    </span>
                    <span
                      v-else
                      class="time-left-text">{{timeLeft}} {{$t('forgetPassword.second')}}</span>
                  </div>
                </template>
              </a-input>
            </a-form-item>
            <a-form-item
              name="username"
              trigger="blur"
              label="First Name"
              autocomplete="off"
              :rules="[{required:true,message:'Enter Your First Name'}]"
              v-if="$store.state.locale === 'enUS'">
              <a-input v-model:value="forgetForm.username" placeholder="Please Enter Your First Name" />
            </a-form-item>
            <a-form-item
              name="lastname"
              trigger="blur"
              label="Last Name"
              autocomplete="off"
              :rules="[{required:true,message:'Enter Your Last Name'}]"
              v-if="$store.state.locale === 'enUS'">
              <a-input v-model:value="forgetForm.lastname" placeholder="Please Enter Your Last Name" />
            </a-form-item>
            <a-form-item
              v-else
              :label="$t('signIn.name')"
              name="username">
              <a-input
                v-model:value="forgetForm.username"
                autocomplete="off"
                :placeholder="$t('signIn.nameTips')">
              </a-input>
            </a-form-item>
            <a-form-item
              :label="$t('signIn.enterPassword')"
              name="password">
              <a-input-password
                v-model:value="forgetForm.password"
                autocomplete="off"
                :placeholder="$t('signIn.passwordTips')"/>
            </a-form-item>
            <a-form-item
              :label="$t('signIn.confirmPassword')"
              name="confirmPassword">
              <a-input-password
                v-model:value="forgetForm.confirmPassword"
                autocomplete="off"
                :placeholder="$t('signIn.confirmPasswordTips')"/>
            </a-form-item>
            <a-form-item>
              <a-button type="primary" html-type="submit" style="width:100%;margin-top:10px" size="large">
                {{$t('signIn.signIn')}}
              </a-button>
            </a-form-item>
          </a-form>
        </a-tab-pane>
        <a-tab-pane key="withEmail" :tab="$t('signIn.withEmail')">
          <a-form
            ref="formRef"
            name="custom-validation"
            :model="forgetForm"
            :rules="forgetRules"
            v-bind="layout"
            class="forget-form"
            @finish="signIn"
            autocomplete="off"
          >
            <a-form-item
              :label="$t('signIn.email')"
              name="email">
              <a-input
                v-model:value="forgetForm.email"
                autocomplete="off"
                :disabled="isInit"
                :placeholder="$t('signIn.emailTips')"/>
            </a-form-item>
            <a-form-item
              name="username"
              trigger="blur"
              label="First Name"
              autocomplete="off"
              :rules="[{required:true,message:'Enter Your First Name'}]"
              v-if="$store.state.locale === 'enUS'">
              <a-input v-model:value="forgetForm.username" placeholder="Please Enter Your First Name" />
            </a-form-item>
            <a-form-item
              name="lastname"
              trigger="blur"
              label="Last Name"
              autocomplete="off"
              :rules="[{required:true,message:'Enter Your Last Name'}]"
              v-if="$store.state.locale === 'enUS'">
              <a-input v-model:value="forgetForm.lastname" placeholder="Please Enter Your Last Name" />
            </a-form-item>
            <a-form-item
              v-else
              :label="$t('signIn.name')"
              name="username">
              <a-input
                v-model:value="forgetForm.username"
                autocomplete="off"
                :placeholder="$t('signIn.nameTips')">
              </a-input>
            </a-form-item>
            <a-form-item
              :label="$t('signIn.enterPassword')"
              name="password">
              <a-input-password
                v-model:value="forgetForm.password"
                autocomplete="off"
                :placeholder="$t('signIn.passwordTips')"/>
            </a-form-item>
            <a-form-item
              :label="$t('signIn.confirmPassword')"
              name="confirmPassword">
              <a-input-password
                v-model:value="forgetForm.confirmPassword"
                autocomplete="off"
                :placeholder="$t('signIn.confirmPasswordTips')"/>
            </a-form-item>
            <a-form-item>
              <a-button type="primary" html-type="submit" style="width:100%;margin-top:10px" size="large">
                {{$t('signIn.signIn')}}
              </a-button>
            </a-form-item>
          </a-form>
        </a-tab-pane>
      </a-tabs>
      <div class="back-button" @click="backToLogin">
        {{$t('signIn.backToLogin')}}
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, reactive, toRefs, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter, useRoute } from 'vue-router'
import api from '@/services/api'
import { message } from 'ant-design-vue'
export default defineComponent({
  name: 'SignIn',
  components: {},
  props: {},
  setup (props, context) {
    const router = useRouter()
    const route = useRoute()
    console.log(route)
    const { t } = useI18n()

    const formRef = ref(null)

    const emailValidate = async (rule, value) => {
      var emailReg = /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/
      if (value === '') {
        return Promise.reject(new Error(t('signIn.emailNullError')))
      } else if (!emailReg.test(value)) {
        return Promise.reject(new Error(t('signIn.emailRuleError')))
      } else {
        return Promise.resolve()
      }
    }

    const usernameValidate = async (rule, value) => {
      if (value === '') {
        return Promise.reject(new Error(t('signIn.userNullError')))
      } else {
        return Promise.resolve()
      }
    }

    const passwordValidate = async (rule, value) => {
      // todo 临时注释 const passwordReg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/
      if (value === '') {
        return Promise.reject(new Error(t('signIn.passwordNullError')))
      // todo 临时注释 } else if (!passwordReg.test(value)) {
      } else if (value && value.length < 8) {
        return Promise.reject(new Error(t('signIn.passwordRuleError')))
      } else {
        return Promise.resolve()
      }
    }

    const phoneValidate = async (rule, value) => {
      var phoneReg = /0?(1)[0-9]{10}/
      if (!value || value === '') {
        return Promise.reject(new Error(t('signIn.phoneNullError')))
      } else if (!phoneReg.test(value)) {
        return Promise.reject(new Error(t('signIn.phoneRuleError')))
      } else {
        return Promise.resolve()
      }
    }

    const codeValidate = async (rule, value) => {
      if (!value || value === '') {
        return Promise.reject(new Error(t('signIn.codeRoleError')))
      } else if (value && value.length < 6) {
        return Promise.reject(new Error(t('signIn.codeRoleError')))
      } else {
        return Promise.resolve()
      }
    }

    const checkConfirm = async (rule, value) => {
      if (value === '') {
        return Promise.reject(new Error(t('signIn.checkPassNullError')))
      } else if (state.forgetForm.password !== value) {
        return Promise.reject(new Error(t('signIn.checkPassDifferentError')))
      } else {
        return Promise.resolve()
      }
    }

    const state = reactive({
      forgetForm: {
        email: '',
        username: '',
        password: '',
        confirmPassword: '',
        lastname: ''
      },
      forgetRules: {
        phone: [{ validator: phoneValidate, trigger: 'blur', required: true }],
        smsCode: [{ validator: codeValidate, trigger: 'blur', required: true }],
        email: [{ validator: emailValidate, trigger: 'blur', required: true }],
        username: [{ validator: usernameValidate, trigger: 'blur', required: true }],
        password: [{ validator: passwordValidate, trigger: 'blur', required: true }],
        confirmPassword: [{ validator: checkConfirm, trigger: 'blur', required: true }]
      },
      isInit: false,
      canGetCode: true,
      timeLeft: 0
    })

    onMounted(async () => {
      initForm()
    })

    const getCode = async () => {
      try {
        await formRef.value.validate(['phone'])
      } catch (error) {
        return
      }
      try {
        const res = await api.getCodeWhenSignin({
          phoneString: state.forgetForm.phone
        })
        if (res && res.code === 200) {
          message.success(t('signIn.sendSuccess'))
        } else {
          if (res.msgDetail) {
            message.error(res.msgDetail)
            return
          }
        }
      } catch (error) {
        console.error(error)
        return
      }
      state.canGetCode = false
      state.timeLeft = 120
      const interval = setInterval(() => {
        state.timeLeft -= 1
        if (state.timeLeft <= 0) {
          state.canGetCode = true
          clearInterval(interval)
        }
      }, 1000)
    }

    const initForm = () => {
      const email = route.query?.email
      if (email && email.length) {
        state.isInit = true
        state.forgetForm.email = email
      }
    }

    const layout = {
      layout: 'vertical'
    }

    const signIn = (values, iPhone) => {
      console.log(values)
      context.emit('signIn', {
        ...values,
        username: values.username + (values.lastname ? ` ${values.lastname}` : '')
      }, iPhone)
    }

    const backToLogin = () => {
      const num = Object.keys(route.query)
      console.log(num)
      router.push({
        name: 'Login',
        query: { redirect: window.location.href + `${num && num.length ? '&' : '?'}join=true` }
      })
    }

    return {
      layout,
      signIn,
      backToLogin,
      getCode,
      formRef,
      ...toRefs(state)
    }
  }
})
</script>
<style lang='less' scoped>
.model-title {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 24px;
}
.forget-model {
  padding: 24px 48px 0;
  .code-input-suffix {
    width: 80px;
    text-align: center;
    .divide-line {
      border-left: solid 1px rgba(0,0,0,0.2);
      height: 30px;
      position: absolute;
      transform: translate(-10px, -50%);
      top: 50%;
    }
    .get-code-text {
      color: #209FFD;
      cursor: pointer;
    }
    .time-left-text {
      color: rgba(0,0,0,0.4);
      cursor: default;
    }
  }
}
.back-button {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  color: #209FFD;
  cursor: pointer;
}
</style>
<style lang="less">
.forget-form {
  .ant-form-item-control-input .ant-form-item-control-input-content{
    align-items: flex-end;
    min-height: 48px;
  }
  .ant-input{
    padding: 13px 0px;
    border-style: none none solid;
    border-radius: 0px;
    &:focus {
      box-shadow: 0 2px 4px -2px rgba(24, 144, 255, 0.2);
    }
  }
  .ant-input-affix-wrapper {
    padding: 13px 0px;
    border-style: none none solid;
    border-radius: 0px;
    &-focused {
      box-shadow: 0 2px 4px -2px rgba(24, 144, 255, 0.2);
    }
  }
  .ant-form-item-label, .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label {
      padding: 0 0 0;
      line-height: 1.5715;
      white-space: initial;
      text-align: left;
  }
}
.forget-model {
  .ant-btn-lg {
    height: 48px;
    padding: 6.4px 12px;
    font-size: 16px;
    border-radius: 8px;
  }
}
</style>
